import { Box, Divider, Button } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../../../../src/components/seo";
import SupportHero from "../../../../src/components/SupportHero";
import * as React from 'react';
export default {
  Box,
  Divider,
  Button,
  AniLink,
  SEO,
  SupportHero,
  React
};