/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "@fontsource/roboto"

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `Thinkanewmedia.com has been updated. Reload to display the latest version?`
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }
export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
