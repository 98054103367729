/** @jsx jsx */
import { jsx, Label, Button, Box, Input, Textarea } from "theme-ui"
import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

const ContactForm = () => {
  const [canSend, setCanSend] = useState(false)
  const [customerName, setCustomerName] = useState("")
  const [customerNameError, setCustomerNameError] = useState(false)
  const [customerEmail, setCustomerEmail] = useState("")
  const [customerEmailError, setCustomerEmailError] = useState(false)
  const [customerPhone, setCustomerPhone] = useState("")
  const [customerNote, setCustomerNote] = useState("")
  const [customerNoteError, setCustomerNoteError] = useState(false)

  function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  }

  function canISend() {
    if (
      customerName.length > 2 &&
      !customerNameError &&
      customerEmail.length > 4 &&
      !customerEmailError &&
      customerNote.length > 5 &&
      !customerNoteError
    ) {
      setCanSend(true)
      return
    }
    setCanSend(false)
  }

  function handleCustomerNameChange(input) {
    let newValue = input.target.value
    if (newValue.length > 50) {
      setCustomerName(newValue.slice(0, 50))
    } else {
      setCustomerName(newValue)
    }

    if (newValue.length < 3) {
      setCustomerNameError(true)
    } else {
      setCustomerNameError(false)
    }
    canISend()
  }

  function handleCustomerEmailChange(input) {
    let newValue = input.target.value
    if (newValue.length > 50) {
      setCustomerEmail(newValue.slice(0, 50))
    } else {
      setCustomerEmail(newValue)
    }

    if (!isEmail(newValue)) {
      setCustomerEmailError(true)
    } else {
      setCustomerEmailError(false)
    }
    canISend()
  }

  function handleCustomerPhoneChange(input) {
    let newValue = input.target.value

    if (newValue.length > 50) {
      setCustomerPhone(newValue.slice(0, 50))
    } else {
      setCustomerPhone(newValue)
    }
  }

  function handleCustomerNoteChange(input) {
    let newValue = input.target.value
    if (newValue.length > 1024) {
      setCustomerNote(newValue.slice(0, 1024))
    } else {
      setCustomerNote(newValue)
    }

    if (customerNote.length < 5) {
      setCustomerNoteError(true)
    } else {
      setCustomerNoteError(false)
    }
    canISend()
  }

  return (
    <div>
      <Box
        as="form"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        action="/thank-you"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <Label htmlFor="name">Your name</Label>
        <Input
          type="text"
          name="name"
          mb={3}
          value={customerName}
          onChange={input => {
            handleCustomerNameChange(input)
          }}
        />
        {customerNameError && (
          <span sx={{ color: "error", fontSize: [0, 1] }}>
            Name is a required field.
          </span>
        )}
        <Label htmlFor="email">Your email</Label>
        <Input
          type="email"
          name="email"
          mb={3}
          value={customerEmail}
          onChange={input => {
            handleCustomerEmailChange(input)
          }}
        />
        {customerEmailError && (
          <span sx={{ color: "error", fontSize: [0, 1] }}>
            Email address is a required field.
          </span>
        )}
        <Label htmlFor="phone">Your phone number (optional)</Label>
        <Input
          type="text"
          name="phone"
          mb={3}
          value={customerPhone}
          onChange={input => {
            handleCustomerPhoneChange(input)
          }}
        />
        <Label htmlFor="message">Project Details</Label>
        <Textarea
          name="message"
          placeholder=""
          rows="4"
          value={customerNote}
          onChange={input => {
            handleCustomerNoteChange(input)
          }}
        />
        {customerNoteError && (
          <span sx={{ color: "error", fontSize: [0, 1] }}>
            Message is a required field.
          </span>
        )}
        <br />
        <p
          sx={{
            variant: "styles.p",
            mb: "5px",
          }}
        >
          Confirm you are a human!
        </p>
        <ReCAPTCHA
          sitekey={process.env.SITE_RECAPTCHA_KEY}
          style={{ height: "78px", marginTop: "0px" }}
        />
        {canSend ? (
          <Button
            sx={{
              marginTop: "50px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            type="submit"
          >
            Send Message
          </Button>
        ) : (
          <Button
            sx={{
              marginTop: "50px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            type="submit"
            disabled
          >
            Send Message
          </Button>
        )}
      </Box>
    </div>
  )
}

export default ContactForm
