import { Container, Box } from "theme-ui";
import SEO from "../../../../src/components/seo";
import PrivacyHero from "../../../../src/components/PrivacyHero";
import * as React from 'react';
export default {
  Container,
  Box,
  SEO,
  PrivacyHero,
  React
};