import { Grid, Box, Heading, Text, Image, Button, Divider } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../../../../src/components/seo";
import WebDesignHero from "../../../../src/components/WebDesignHero";
import GatsbyLogo from "../../../../src/images/Gatsby-Logo.svg";
import * as React from 'react';
export default {
  Grid,
  Box,
  Heading,
  Text,
  Image,
  Button,
  Divider,
  AniLink,
  SEO,
  WebDesignHero,
  GatsbyLogo,
  React
};