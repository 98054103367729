// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-book-a-meeting-mdx": () => import("./../../../src/pages/book-a-meeting.mdx" /* webpackChunkName: "component---src-pages-book-a-meeting-mdx" */),
  "component---src-pages-consulting-mdx": () => import("./../../../src/pages/consulting.mdx" /* webpackChunkName: "component---src-pages-consulting-mdx" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-hosting-and-email-mdx": () => import("./../../../src/pages/hosting-and-email.mdx" /* webpackChunkName: "component---src-pages-hosting-and-email-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-support-maintenance-mdx": () => import("./../../../src/pages/support-maintenance.mdx" /* webpackChunkName: "component---src-pages-support-maintenance-mdx" */),
  "component---src-pages-thank-you-mdx": () => import("./../../../src/pages/thank-you.mdx" /* webpackChunkName: "component---src-pages-thank-you-mdx" */),
  "component---src-pages-web-app-development-mdx": () => import("./../../../src/pages/web-app-development.mdx" /* webpackChunkName: "component---src-pages-web-app-development-mdx" */),
  "component---src-pages-web-design-development-mdx": () => import("./../../../src/pages/web-design-development.mdx" /* webpackChunkName: "component---src-pages-web-design-development-mdx" */)
}

