const ThemeStyle = {
  space: [0, 4, 8, 16, 36, 50, 128, 256, 512],
  fonts: {
    body:
      '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      '"Roboto", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [14, 16, 20, 24, 30, 36, 42, 56, 70],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.4,
    boxheading: 1.4,
  },
  colors: {
    text: "#444",
    textWhite: "#fff",
    heading: "#333",
    boxHeading: "#0c6ea5",
    boxText: "#444",
    background: "#fff",
    backgroundLightGrey: "#f5f5f5",
    primary: "#104dc1",
    taBlue: "#034c84",
    // taBlue: "#013b6d",
    taLightBlue: "#0c6ea5",
    linkText: "#CF1F25",
    footerLink: "#CF1F25",
    red: "#bd1621",
    cta: "#CF1F25",
    // pink: "#cc136d",
    muted: "#777",
    disabled: "#ddd",
    error: "#bd1621",
  },
  buttons: {
    primary: {
      color: "white",
      bg: "taLightBlue",
      fontSize: [2, 2],
      paddingLeft: "35px",
      paddingRight: "35px",
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "2px solid",
      borderColor: "taLightBlue",
      transition: "all 0.3s",
      "&:hover, &:focus": {
        cursor: "pointer",
        fontWeight: 500,
      },
      "&:disabled": {
        cursor: "not-allowed",
        bg: "disabled",
        color: "white",
        opacity: 1.0,
        borderColor: "disabled",
        fontWeight: 400,
      },
    },
    cta: {
      color: "textWhite",
      bg: "cta",
      fontSize: [2, 2],
      paddingLeft: "35px",
      paddingRight: "35px",
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "none",
      transition: "all 0.3s",
      "&:hover, &:focus": {
        cursor: "pointer",
        fontWeight: 700,
      },
      "&:disabled": {
        cursor: "not-allowed",
        bg: "disabled",
        color: "taBlue",
        opacity: 1.0,
        fontWeight: 400,
      },
    },
    secondary: {
      cursor: "none",
      color: "white",
      bg: "taBlue",
      "&:hover, &:focus": {
        bg: "text",
        cursor: "pointer",
        fontWeight: 700,
      },
    },
    learnmore: {
      cursor: "none",
      color: "boxText",
      bg: "backgroundLightGrey",
      "&:hover, &:focus": {
        bg: "taLightBlue",
        color: "textWhite",
        cursor: "pointer",
        fontWeight: 700,
      },
    },
  },
  cards: {
    primary: {
      height: "100%",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
    heading: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      marginBottom: 3,
      // fontSize: ["20px", "22px"],
    },
    blockheading: {
      color: "boxHeading",
      textAlign: "center",
      paddingTop: 3,
      paddingLeft: 3,
      paddingRight: 3,
      verticalAlign: "middle",
      fontFamily: "heading",
      lineHeight: "boxheading",
      fontWeight: "heading",
      fontSize: ["20px", "22px"],
    },
    blocktext: {
      color: "boxText",
      paddingLeft: ["1.5rem", "1.5rem", "1.9rem"],
      paddingRight: ["1.5rem", "1.5rem", "1.9rem"],
      paddingBottom: ["1.5rem", "1.5rem", "1.9rem"],
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1, 1, 1],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  forms: {
    label: {
      fontSize: [0, 1],
      fontWeight: "heading",
      marginTop: "30px",
    },
    input: {
      borderColor: "gray",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      borderRadius: "0px",
      marginBottom: "0px !important",
      "&:focus": {
        borderColor: "text",
        boxShadow: t => `0 0 0 0.5px ${t.colors.text}`,
        outline: "none",
      },
    },
    select: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "taLightBlue",
        boxShadow: t => `0 0 0 2px ${t.colors.taLightBlue}`,
        outline: "none",
      },
    },
    textarea: {
      fontFamily: "body",
      borderColor: "gray",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      borderRadius: "0px",
      "&:focus": {
        borderColor: "text",
        boxShadow: t => `0 0 0 0.5px ${t.colors.text}`,
        outline: "none",
      },
      "::placeholder": {
        fontFamily: "body",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [4, 5, 5],
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [4, 4, 4],
    },
    h3: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [2, 2, 3],
    },
    h4: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "body",
      fontSize: [2, 2, 3],
    },
    h5: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "heading",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [0, 1, 1],
    },
    a: {
      color: "linkText",
      "&:hover, &:focus": {
        fontWeight: "inherit",
      },
    },
    menuItem: {
      color: "textWhite",
      "&:hover, &:focus": {
        color: "#00d3dc",
      },
    },
    header: {
      paddingTop: [4, 5],
      paddingBottom: [2, 3],
    },
    footer: {
      paddingTop: [4, 5],
      paddingBottom: [4, 5],
      fontSize: [0, 1],
      color: "#e6e6e6",
      backgroundColor: "taBlue",
    },
    section: {
      paddingTop: "150px",
      paddingBottom: "150px",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    ul: {},
    li: {},
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    hr: {
      color: "heading",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
}

export default ThemeStyle
