/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const PrivacyHero = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "privacy-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#252525`}
          sx={{
            width: "100%",
            height: ["240px", "240px", "360px"],
            backgroundPosition: "center center",
          }}
        >
          <div
            sx={{
              width: "100%",
              height: ["100%"],
              backgroundColor: "#00000070",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1
              sx={{
                variant: "styles.h1",
                fontSize: ["34px", 6, "50px"],
                fontWeight: "700",
                color: "textWhite",
                mt: "0px",
                mb: "0px",
                pl: [3, 5, "80px"],
                pr: [3, 5, "80px"],
                textAlign: ["inherit", "inherit", "inherit"],
                width: "100%",
              }}
            >
              Privacy Policy
            </h1>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default PrivacyHero
