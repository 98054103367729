import { Button, Box, Grid, Card, Heading, Text, Divider } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import HomeHero from "../../../../src/components/HomeHero";
import SEO from "../../../../src/components/seo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneLaptop, faCode, faStore, faServer, faUserShield, faHandsHelping } from '@fortawesome/pro-duotone-svg-icons';
import * as React from 'react';
export default {
  Button,
  Box,
  Grid,
  Card,
  Heading,
  Text,
  Divider,
  AniLink,
  HomeHero,
  SEO,
  FontAwesomeIcon,
  faPhoneLaptop,
  faCode,
  faStore,
  faServer,
  faUserShield,
  faHandsHelping,
  React
};